import Layout from "@components/layout";

import React, { useEffect, useState } from "react";
import { withMKBPlayers, withMKBPlayerCredit } from "@helpers/hoc/withQueries";

import SEO from "@components/common/seo";
import "@styles/global.styles.css";
import { Form, Divider, Spin, Tag } from "antd";
import { extractGuestData } from "@helpers/tools";
import { FlagOutlined, BarChartOutlined } from "@ant-design/icons";
type Props = {
  location?: {
    pathname: string;
  };
  mkbPlayers: {
    notionMKBPlayers: any;
  };
  creditData: any;
  refetchMkbPlayerCredit: Function;
  mkbPlayerCredit: any;
  loadingMkbPlayers: boolean;
  loadingMkbPlayers: boolean;
  loadingMkbPlayerCredit: boolean;
};

const Home: React.FC<Props> = ({
  location,
  mkbPlayers,
  refetchMkbPlayerCredit,
  creditData,
  loadingMkbPlayers,
  loadingMkbPlayerCredit,
  mkbPlayerCredit,
}) => {
  const [form2] = Form.useForm();

  const [open, setOpen] = useState(false);

  const select = extractGuestData(
    mkbPlayers?.notionMKBPlayers?.data?.results
  )?.sort((a, b) => b.handicap - a.handicap);

  const creditSum = mkbPlayerCredit?.mkbPlayerCredit?.data;

  const sortedCreditSum =
    creditSum && [...creditSum].sort((a, b) => a.contribution - b.contribution);

  useEffect(() => {
    refetchMkbPlayerCredit();
  }, [creditData]);

  return (
    <Layout location={location.pathname}>
      <SEO title="Badminton" />
      <div>
        <Divider orientation="left">
          Players Handicap <FlagOutlined />
        </Divider>
        <Spin tip="Loading" size="large" spinning={loadingMkbPlayers}>
          <div className="m-2">
            {select?.map((res, key) => {
              return (
                <div
                  key={key}
                  className="flex items-center w-full py-2 border-b"
                  onClick={() => {
                    form2.setFieldsValue({
                      amount: 0,
                      player: res.value,
                    });
                    setOpen(!open);
                  }}
                >
                  <span className="flex-shrink-0 text-left">
                    <Tag bordered={false}>{key + 1}.</Tag> {res.label}
                  </span>
                  <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
                  <span className="flex-shrink-0 text-right">
                    {res.handicap}
                  </span>
                </div>
              );
            })}
          </div>
        </Spin>
        <Divider orientation="left">
          Players Contribution Stats <BarChartOutlined />
        </Divider>
        <Spin tip="Loading" size="large" spinning={loadingMkbPlayerCredit}>
          <div className="m-2">
            {sortedCreditSum?.map((res, key) => {
              return (
                <div
                  key={key}
                  className="flex items-center w-full py-2 border-b"
                >
                  <span className="flex-shrink-0 text-left">
                    {res.name}
                    <Tag bordered={false}>played {res.count}</Tag>{" "}
                  </span>
                  <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
                  <span className="flex-shrink-0 text-right">
                    {res.contribution}
                  </span>
                </div>
              );
            })}
          </div>
        </Spin>
      </div>
    </Layout>
  );
};

export default withMKBPlayerCredit(withMKBPlayers(Home));
